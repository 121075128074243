/* 
  /* start*/
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
  
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} 

  .container {
    width: 230px;
    background: #2c2c2c;
    height: 480px;
    color: white;
    margin: 10px 10px;
    border-radius: 10px;
    z-index: 10;
    overflow: hidden;
  }
  a {
    color: white;
  }
  .box {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    

  }

  .realimg{
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 1;
   
  }

  .fakeimg{
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 0;
   width: 230px;
   height: 345px;
  }
  .box .content{
    margin: 5px 10px;
  }

  .list{
    list-style: none;
    
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
    margin: 2px 0px;
    color: orange;
  }

  .list li{
    margin: 0 10px;
  }
 .box h2 {
   line-height: 1.1;
    margin-bottom: 10px;
    font-size: 1rem;
    height: 2.5rem;
    /* overflow handling */
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2; /* number of lines to show */
   line-height: X;        /* fallback */
   max-height: X*2;       /* fallback */
  }

  .overlay{
    position: relative;
    width: 230px;
    height: 345px;
    
  }
  .photo{
    position: relative;
    width: 230px;
    height: 345px;
    
  }

  .hour{
    position: absolute;
    bottom: 5px;
    right: 2px;
    padding: 1px 5px;
    background-color: black;
    margin: 1px 1px;
    z-index: 2;
  }

  .rating{
    position: absolute;
    bottom: 5px;
    left: 2px;
    padding: 1px 5px;
    background-color: black;
    margin: 1px 1px;
    z-index: 2;

  }

  .content ul{
    height: 1.6rem;
  }

  .icon{
    font-size: 1rem;
    color: orange;
    bottom: 0px
  }

  .button1{
    background-color: rgb(26, 26, 26);
    color: white;
   border: none;
    padding: 0.3rem 1rem;
    
    font-family: "poppins";
    border-radius: 0.4rem;
    cursor: pointer;
    transition-duration: 0.5s;
    display: flex;
    justify-content: center;
    vertical-align: bottom;
    align-items: center;
    width: 100%;
    }

    /* button1 p{
      display: flex;
      align-items: center;
    vertical-align: bottom;
   
    } */
    
    .button1:hover{
      background-color: white;
    color: black;
    border: none;
    }

    .button2{
      background-color: rgb(112, 112, 112);
      color: white;
     border: none;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      font-family: "poppins";
      border-radius: 0.4rem;
      cursor: pointer;
      transition-duration: 0.5s;
      display: flex;
      justify-content: center;
      vertical-align: bottom;
      align-items: center;
      width: 100%;
      }
  
      /* button2 p{
        display: flex;
        align-items: center;
      vertical-align: bottom;
     
      } */
      
      .button2:hover{
        background-color: white;
      color: black;
      border: none;
      }



    .listbtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.4rem;
    }

    .genrecentre{
      display: flex;
    justify-content: center;
    }

    .content h2{
      display: flex;
    justify-content: center;
    text-align: center;
    }

    .tooltip {
      position: relative;
      display: inline-block;
      width: 100%;
    }
    
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 160px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 7px 0;
      position: absolute;
      z-index: 1;
      bottom: 130%;
      font-size: 0.7rem;
      /* left: 50%; */
      /* margin-left: -60px; */
    }
    
    .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    
    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .buttonAligner{
      display: flex;
       align-items:center;
    }

    .buttonAligner svg{
      font-size: 1.5rem;
    }

    /* @media (max-width: 420px){
      .button1 {
        background-color: black;
        color: white;
        border: 5px solid white;
        padding: 1px 15px;
        margin: 5px 10px;
        font-family: "poppins";
        border-radius: 30px;
        cursor: pointer;
        transition-duration: 0.5s;
        display: flex;
        justify-content: center;
        vertical-align: bottom;
        align-items: center;
        font-size: 2.5rem;

      }

      .buttonAligner svg{
        font-size: 5rem;
      }

     
    } */