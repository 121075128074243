
.spinner-container {
  position: relative;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 3px solid rgb(204, 204, 204);
  border-top-color: rgb(255, 196, 0);
  animation: spinner 0.6s linear infinite;
}
/* body{
  min-width: 1280px;
} */

/* @media (max-width: 420px){
  .body{
      min-width: 100vw;
  }
  
} */