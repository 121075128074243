@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.header{
background: black;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr)); */
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;

    
}


a { text-decoration: none; }

.contents{
    max-width: 1280px;
    display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  margin-top: 50px;
}

.carouselContainer{
    position: relative;
    max-width: 1280px;
}

.rightButton{
    position: absolute;
    right: 1px;
    font-size: 30px;
}

.listTitleGroup{
    
    margin: 15px;
    
    
}

.listTitleGroup h2{
    color: #FFAE42;
}

.deleteButton {

    background-color: #FFAE42;
    height: 1.9rem;
    border-radius: 5px;
    padding:0 30px;
}
.deleteButton:hover{
    background-color: #ffb85c;
}

.noUserPrompt{
    background-color: rgb(97, 97, 97);
    padding: 1rem;
    border-radius: 0.5rem;
    /* height: 25rem; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.noUserLeft{
    width: 50%;
    font-family: "poppins";
    color: white;
    padding: 1rem;
display: flex;
flex-direction: column;
justify-content: center;
border-right: 1px solid rgb(187, 187, 187);
}

.noUserLeftTitle{
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1.9rem;
    line-height: 1.7rem;
}

.noUserLeftContent1{
    font-size: 1rem;
    font-weight: 600;
    color: rgb(224, 224, 224);
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.noUserLeftContent2{
    font-size: 0.8rem;
    /* line-height: 0.9rem; */
   margin-bottom: 1.4rem;
    
}

.noUserRight{
    width: 50%;
    font-family: "poppins";
    color: white;
    padding: 1rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 1rem

}

.noUserRightTitle{
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.login{
    background-color: rgb(27, 27, 27);
    font-family: "poppins";
    color: white;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    width: 70%;
}

.register{
    background-color: rgb(65, 65, 65);
    font-family: "poppins";
    color: white;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    width: 70%;
}

.noUserRight a {
    display: flex;
    justify-content: center;
width: 100%;
margin: 0 auto;
}


.gridScroll{
    display: flex;
   
   overflow-x: auto;
    scroll-behavior: smooth;
    
}
.gridScroll::-webkit-scrollbar {
    display: none;
  }

  .sliderContainer{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    position: relative;
    scroll-behavior: smooth;
    /* padding: 0 1rem; */
  }

  .sliderButtonLeft{
    background-color: rgb(170, 170, 170);
    opacity: 0.7;
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sliderButtonRight{
    background-color: rgb(170, 170, 170);
    opacity: 0.7;
    position: absolute;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .emptyListContainer{
    padding:1rem;
    /* background-color: rgb(83, 83, 83); */
    /* border: 1px solid rgb(119, 119, 119); */
    margin: 1rem;
    border-radius: 1rem;
    height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }

  .addListContainer{
    padding:1rem;
    /* background-color: rgb(83, 83, 83); */
    /* border: 1px solid rgb(119, 119, 119); */
  
    height: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(250, 250, 250);
  }


  .toHomeButton{
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    margin-top: 2rem;
    font-weight: 600;
    background-color: rgb(43, 43, 43);
    color: white;
font-size: 0.9rem;
  }

  .addListText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .contentOptions{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .optionBtn{
    border: none;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(224, 224, 224);
  }

  .optionBtn:hover{
    background-color: rgb(114, 114, 114);
  }

  .contentTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contentLeft{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .descToggle{
    width: 1.8rem;
    height: 1.8rem;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(224, 224, 224);
    border-radius: 0.2rem;
  }

  .descToggle:hover{
    background-color: rgb(114, 114, 114);
  }

  .descContainer{
    margin: 0.5rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
border-radius: 0.5rem;

  }

  .descContainerNone{
    display: none;
  }
  body html {scroll-behavior: smooth;}
@media (max-width: 480px){
    .noUserRight{width: 100%}
    .noUserLeft{width: 100%; border: none; border-bottom:1px solid rgb(187, 187, 187);
    ;}
}