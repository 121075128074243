@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
  }
.header{
background: black;
margin-top: 50px;
}
.genre{
    color: white;
    /* background: rgb(59,55,117);
    background: linear-gradient(143deg, rgba(59,55,117,1) 0%, rgba(0,121,114,1) 24%, rgba(247,203,125,1) 73%); */
    background: rgb(0,0,0);
background: linear-gradient(140deg, rgba(0,0,0,1) 7%, rgba(61,54,54,1) 37%, rgba(233,179,81,1) 88%);
    padding: 1rem 1rem;
    font-family: "poppins";
    max-width: 1280px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* height: 250px; */
    
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr)); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    
}

.pageset{
    display: flex;
    color: white;
    font-family: "poppins";
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 2rem 1rem;
position: relative;
max-width: 1280px;
margin: 0 auto;
}

.pagesetTop{
    position: absolute;
    right: 1rem;
    background-color: #a0a0a0;
    color: white;
    padding: 0.5rem 0.7rem;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.pagesetTop:hover{
    background-color: #5f5f5f;
}


.pagesetNumbers{
    margin: 0 10px;
    font-size: 1.1rem;
    
}

.pagesetPrev {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 0.5rem;
    background-color: #5f5f5f;
    border-radius: 0.5rem;
    height: 2rem;
    width: 2rem;
    justify-content: center;
}
.pagesetNext{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    background-color: #5f5f5f;
    border-radius: 0.5rem;
    height: 2rem;
    width: 2rem;
    justify-content: center;
}

.pagesetNext:hover, .pagesetPrev:hover{
    background-color: #a7a7a7; 
}

.bars{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 40px; */
 
    max-width: 640px;
    margin: 0 auto;
    /* border-radius: 50px;
    overflow: hidden;
    perspective: 1px; */
}

.barcontainer{
   
    /* max-height: 120px; */


   
    /* overflow: hidden; */
}

.text{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin: 15px 
}
.genrebar, .ratingbar{
    
    /* align-items: center; */
    /* justify-content: left; */
    color: white;
    font-family: "poppins";
    width: 25%;
    margin: 5px 0;
}

.genrebar{
    border-radius: 30px;
}


select {
    appearance: none;
    border: none;
    font-size: 0.9rem;
    font-family: "poppins";
    padding: 0 10px;
    width: 100%;
    
    
}
input, form {
    appearance: none;
  
}

option:first-of-type{
    font-weight: 600;
}
.formbar{
    /* margin: 10px 10px; */
    font-family: "poppins";
    color: white;
    width: 50%;
    margin: 5px 0;
    
}

.formbar form{
    width: 100%;
    display: flex;
    align-items: center;
    
}



.formsize{
    height: 40px;
    
    border: none;
    padding: 10px 10px;
    font-family: "poppins";
    font-size: 0.9rem;
    width: calc(100% - 40px);
}

.button{
    height: 40px;
    width: 40px;
    border: none;
    font-size: 1rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* border-bottom-right-radius: 15px;
    border-top-right-radius: 15px; */
}

.button svg{
    margin: auto;
}

.button:hover{
    background-color: gainsboro;
}

select {
    /* width: 100px; */
    height: 40px;
    align-items: center;
    /* margin: 10px 10px; */
    

}
a { text-decoration: none; }

.contents{
    max-width: 1280px;
    display: block;
  margin-left: auto;
  margin-right: auto;
}

.loading{
    color: white;
}


.dummyContainer{
    --containerWidth: 215px;
    width: var(--containerWidth);
    background: #686868;
    height: calc( var(--containerWidth) * 2 );
    color: white;
    margin: 10px 10px;
    border-radius: 10px;
    z-index: 10;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation: blink 1s linear infinite;
}

.noResults{
    background-color: #363636;
    border-radius: 0.5rem;
    margin: 1rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.noResultsLine1{
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "poppins";
}
.noResultsLine2{
    color: white;
    font-size: 0.9rem;
    font-family: "poppins";
}

.noResultsButton{
background-color: white;
color: #363636;
    font-size: 0.9rem;
    font-family: "poppins";
    padding: 0.5rem 1rem;
    margin-top: 3rem;
    border-radius: 0.5rem ;
    cursor: pointer;
   
}

.noResultsButton:hover{
    background-color: #c9c9c9;
}

@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }

@media (max-width: 600px){
    .contents{
        max-width: 100vw;
    }
    .genre{
        max-width: 100vw;
    }
    .bars{
        flex-wrap: wrap;
    }
    .text{
        font-size: 0.9rem;
    }
    .genrebar {
        width: 100%;
        border-radius: 5px;
    overflow: hidden;
    perspective: 1px;
    }
    .ratingbar {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        perspective: 1px;
    }
    .formbar {
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        
    }
    .text{
        display: none;
    }
    .pagesetTop span{
        display: none;
    }
}

@media (max-width: 488px){
    .dummyContainer{
      --containerWidth: 180px;
      margin: 5px;
    }

    
  }

  @media (max-width: 400px){
    .dummyContainer{
      --containerWidth: 170px;
      margin: 5px;
    }

    
  }
