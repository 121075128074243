@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Outline&family=Titillium+Web&display=swap');
.topListContainer{
    background-color:black;
    padding: 1rem;
   
    width: 100%;
    position: relative;
}


.topListSingleContainer{
    min-width: 220px;
    
    display: flex;
    background-color: #2c2c2c;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 0.5rem;
}
.singleLeft{
    width: 20%;
    font-family: 'Titillium Web';
    font-size: 4.5rem;
    display: flex;
    justify-content: center;
    color: rgb(221, 221, 221);
}

.singleRight{
    width: 80%;

}
.singleRightImage{
    width: 100%;
    height: 100%;
    
}

/* start */
.sliderContainer{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    position: relative;
    scroll-behavior: smooth;
    /* padding: 0 1rem; */
   
  }

  .sliderButtonLeft{
    background-color: rgb(170, 170, 170);
    opacity: 0.7;
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sliderButtonRight{
    background-color: rgb(170, 170, 170);
    opacity: 0.7;
    position: absolute;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gridScroll{
    display: flex;
   
   overflow-x: auto;
    scroll-behavior: smooth;

position: relative;
}
.gridScroll::-webkit-scrollbar {
    display: none;
  }

  .topListSingleContainerHovered{
    min-width: 380px;
    height: 18rem;
    background-color: red;
    z-index: 1000;
    position: absolute;
  }
  .containerInc{
    min-width:500px;
  }

  .loadingSingleContainer{
    min-width: 220px;
    height: 273px;
    background-color: #686868;
    border-radius: 0.5rem;

    margin: 0 0.5rem;
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }