/* *{
    box-sizing: border-box;
    margin:0;
    padding: 0;
    background-color: #24252A;
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} /*enable all container to wrap to edges*/

.container{
    background: #2c2c2c;
    height: 50px;
   position: fixed;
   width: 100%;
   top:0;
   z-index: 100;
   
}
.navbar {
    display: flex;
   
   justify-content: space-between;
    align-items: center;
    /* padding: 30px 3%; */
    
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    /* padding: 0 30px; */
    
}




.navbar a { color: #fff;
    text-decoration: none; }



.navlinks {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
}




.navlinks li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 100%;
}

/* .navlinks li:hover{
    background-color: rgb(110, 110, 110);
    transition: 0.4s;

   
} */

.navlinks li:hover  {
    color: rgb(230, 171, 82);
    /* font-weight: bold; */
    transition: 0.4s;

   
}
/* 
.navlinks a:hover {color:rgb(223, 138, 26)} */

.logoContainer{
    width: 30px;
    height: 30px;
    border: solid white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.logo{
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
}

.menuLogoContainer{
    height: 100%;
    display: flex;
    align-items: center;
    display: none;
    margin-right: 1rem;
}
.menuLogo{
    font-size: 2rem;
    color: white;
}
.navlinks #hidden{
    visibility: hidden;
}

@media (max-width: 480px){
    /* .container{
        max-width: 100vw;
    } */
    .navbar{
        max-width: 100vw;
        flex-wrap: wrap;
        z-index: 1000;
    }

    .logoText{
        display: none;
    }
    .navlinks{
        /* display: none; */
        flex-direction: column;
        width: 100%;
        z-index: 1000;
        background-color: #2c2c2c ;
    }

    .navlinks li{
        background-color: #2c2c2c ;
        width: 100%;
        /* display: flex;
        justify-content: center; */
    }

    /* .navlinks li a{
        width: 100%;
        
    } */
    .menuLogoContainer{
      
        display: flex;
    }
    .navlinkContainerOn{
        width: 100%;
        z-index: 1000;
        display: flex;
        transition: 0.4s;
    }
    .navlinkContainerOff{
        width: 100%;
        z-index: 1000;
        display: none;
        transition: 0.4s;
    }
    
    
}